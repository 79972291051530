#game{
  background: url(./assets/background_game.jpg);
  background-size: cover;
}

#game:hover{
  cursor: none;
}

.container_custom{
  height: 100vh;
  width: 100vw;
  overflow: scroll;
  -webkit-overflow-scrolling: touch; /* Active le défilement lisse sur iOS */
  overflow: scroll;
}

.home_container {
  background: url(./assets/background_home.png);
  background-size: cover;
  background-position: fixed;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow: scroll;
}

.home_container_start{
  background: url(./assets/bg_start_page.jpg);
  background-size: cover;
  background-position: fixed;
  position: fixed;
  overflow: scroll;
  scrollbar-width: none;
}

.input{
  font-size: 2em;
}

.error_msg{
  color: brown;
  font-size: 1.4em;
}

label{
  font-size: 1.4em;
}

.btn_start{
  background: #74BE8B;
  color: white;
  font-weight: bold;
}

.btn_start:hover{
  background-color: #583328;
  color: white;
}

.c-brun{
  margin-top: 140px;
  color: #4D2B21;
}

.btn_prizes{
  color: #74BE8B;
  border-radius: 0.4em;
  font-size: 1.4em;
  outline: none;
  background: #FFFCE5;
  padding: 0.4em;
}

.btn_prizes:hover{
  background: #f1f0e5;
  color: #66b680;
}

.btn_code{
  background: #74BE8B;
  color: #FFFCE5;
  border-radius: 0.4em;
  outline: none;
  font-size: 1.4em;
  padding: 0.4em;
  margin-bottom: 100px !important;
}

.btn_code:hover{
  background: #66b680;
  color: #f1f0e5;
}

button{
  width: 100%;
}

.c-white{
  color: white;
}

.sprite{
  position: absolute;
}

.graine{
  animation: graineRotation 3s infinite normal;
  background: url(./assets/graine.png);
  background-size: cover;
}

.malus{
  animation: graineRotation 3s infinite normal;
  background: url(./assets/malus.png);
  background-size: cover;
}

.ors{
  animation: graineRotation 3s infinite normal;
  background: url(./assets/ors.png);
  background-size: cover;
}

#controller{
  background: url(./assets/controller.png);
  background-size: 100%;
}

.title_ors-fr{
  height: 20%;
  width: 25%;
  left: 37.5%;
  top: 12%;
  z-index: 0;
  animation: pulse 1s linear infinite;
  transition-duration: 0.5;
  background: url(./assets/title-fr.png) no-repeat;
  background-size: cover;
}

.title_ors-nl{
  height: 20%;
  width: 25%;
  left: 37.5%;
  top: 12%;
  z-index: 0;
  animation: pulse 1s linear infinite;
  transition-duration: 0.5;
  background: url(./assets/tile-nl.png) no-repeat;
  background-size: cover;
}

.no-display{
  opacity: 0;
}

.score, .timer{
  border-radius: 50%;
  background: #FFFCE5;
  color: brown;
  text-align: center;
  font-size: 1em;
}

.scale{
  animation: scale 300ms linear;
}

.malus-controller{
  animation: malusScale 300ms linear;
}

.border-argent{
  border: solid 0.4em silver;
}

.border-ors{
  border: solid 0.4em goldenrod;
}

.shadow-gold{
  box-shadow: 0em 0em 1em 1em rgba(218, 165, 32, 0.8);
  animation: pulseShadow 1s infinite linear;
}

#progress{
  border: solid 0.1em silver;
}

.pulseCompteur{
  font-size: 4em;
  animation: pulse 1s infinite linear;
}

.c-pointor:hover{
  cursor: pointer;
}

#btn_lang{
  top: 3%;
  right: 3%;
  position: absolute;
  background: #66b680;
  color: white;
  border-radius: 30px;
  font-size: 2em;
  text-align: center;
  height: 60px;
  width: 60px;
  border: none;
  outline: none;
}

#btn_lang:hover{
  background: #74BE8B;
}

@keyframes pulseShadow {
  0%{
    box-shadow: 0em 0em 1em 1em rgba(218, 165, 32, 0.6);
  }
  50%{
    box-shadow: 0em 0em 1em 1em rgba(218, 165, 32, 1);
  }
  100%{
    box-shadow: 0em 0em 1em 1em rgba(218, 165, 32, 0.6);
  }
}

@keyframes pulse {
  0%{
    scale: 1;
  }
  50%{
    scale: 1.2;
  }
  100%{
    scale: 1;
  }
}

@keyframes scale {
  0%{
    scale: 1;
  }
  50%{
    scale: 1.2;
  }
  100%{
    scale: 1;
  }
}

@keyframes malusScale {
  0%{
    scale: 1;
  }
  50%{
    scale: 0.8;
  }
  100%{
    scale: 1;
  }
}

@keyframes graineRotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 360px) {
  #game{
    width: 360px;
    height: 202.5px;
  }
}

@media only screen and (min-width: 385px) {
  #game{
    width: 385px;
    height: 216.5625px;
  }
}


@media only screen and (min-width: 410px) {
  #game{
    width: 380px;
    height: 213.75px;
  }
}

@media only screen and (min-width: 560px) {
  #game{
    width: 460px;
    height: 278.75px;
  }
}

@media only screen and (min-width: 600px) {
  #game{
    width: 500px;
    height: 281.25px;
  }
}

@media only screen and (min-width: 640px) {
  #game{
    width: 510;
    height: 286.875px;
  }
}

@media only screen and (min-width: 740px) {
  #game{
    width: 640px;
    height: 360px;
  }
}

@media only screen and (min-width: 1080px) {
  #game{
    width: 1080px;
    height: 607.5px;
  }
}

@media only screen and (min-width: 1280px) {
  #game{
    width: 1280px;
    height: 720px;
  }
}

@media only screen and (min-width: 1920px) {
  #game{
    width: 1920px;
    height: 1080px;
  }
}